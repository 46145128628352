.header {
  div,
  applet,
  object,
  iframe,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  span,
  pre,
  a,
  abbr,
  acronym,
  address,
  big,
  cite,
  code,
  del,
  dfn,
  em,
  img,
  ins,
  kbd,
  q,
  s,
  samp,
  small,
  strike,
  strong,
  sub,
  sup,
  tt,
  var,
  b,
  u,
  i,
  center,
  dl,
  dt,
  dd,
  ol,
  ul,
  li,
  fieldset,
  form,
  label,
  legend,
  table,
  caption,
  tbody,
  tfoot,
  thead,
  tr,
  th,
  td,
  article,
  aside,
  canvas,
  details,
  embed,
  figure,
  figcaption,
  footer,
  header,
  hgroup,
  menu,
  nav,
  output,
  ruby,
  section,
  summary,
  time,
  mark,
  audio,
  video {
    font: revert;
    margin: revert;
    font-family: revert;
  }
  figure,
  img {
    display: flex;
    margin-left: auto;
    margin-right: auto;
  }

  /* figure {
    box-shadow: 0px 2px 20px rgba(0, 0, 0);
  } */

  p > img {
    box-shadow: 0px 2px 20px rgba(0, 0, 0);
  }

  figure > img {
    box-shadow: 0 2px 20px #000 !important;
  }

  td > p {
    font-size: 14px !important;
  }
  h1 > a {
    color: #0000ff;
    margin: 0px 4px;
  }
  h2 > a {
    color: #0000ff;
    margin: 0px 4px;
  }
  h3 > a {
    color: #0000ff;
    margin: 0px 4px;
  }
  li > a {
    color: #0000ff;
    margin: 0px 4px;
  }

  h1 {
    color: #000;
    font-size: 2em;
    line-height: 2em;
    margin-bottom: 0.6em;
    font-weight: 700;
  }
  h2 {
    color: #000;
    font-size: 24px;
    line-height: 50px;
    margin-bottom: 2.4px;
    font-weight: 700;
    margin-top: 0;
  }
  h3 {
    color: #000;
    font-size: 22px;
    line-height: 1.8;
    margin-bottom: 0.2em;
    font-weight: 700;
  }
  h4 {
    color: #000;
    font-size: 20px;
    line-height: 1.4em;
    margin-bottom: 0.1em;
    font-weight: 700;
    display: flex;
  }
  p {
    font-size: 18px;
    line-height: 1.8;

    margin-block-start: 0px !important;
    margin-block-end: 0em !important;
    margin-inline-start: 0px !important;
    margin-inline-end: 0px !important;
    color: #000;
  }

  p a {
    color: blue;
  }

  td > a {
    color: rgba(0, 0, 255, 0.829);
  }

  p strong {
    color: #000;
  }

  li > strong {
    display: contents !important;
  }

  h5 {
    margin-bottom: 12px;
    margin-top: 12px;
    color: #000;
    font-size: 18px;
  }

  figcaption {
    background-color: var(--ck-color-image-caption-background);
    caption-side: bottom;
    color: var(--ck-color-image-caption-text);
    display: table-caption;
    font-size: 0.75em;
    outline-offset: -1px;
    word-break: break-word;
  }
  blockquote {
  }
  .table {
    margin: 0;
  }
  ul.todo-list {
    margin: revert;
    color: revert;
    font-family: revert;
    margin-left: 32px;
  }
  ul,
  ol {
    list-style: initial;
  }

  li {
    margin-left: 13px;
  }

  ol li {
    list-style: block;
    display: list-item;
    margin-left: 22px;
  }

  ul li:before {
    content: '';
    display: inline-block;
    width: 5px;
    height: 5px;
    background-color: black;
    border-radius: 50%;
    margin-right: 5px;
    margin-top: 10px;
    position: absolute;
    left: 0;
  }
  sub {
    vertical-align: sub;
  }
  sup {
    vertical-align: super;
  }
  figure {
    overflow-x: auto !important;
  }

  overflow-x: auto !important;

  table {
    width: 100%;
    font-size: 14px !important;
  }

  th,
  td {
    border: 1px solid rgb(222, 224, 227);
    text-align: center;
    padding: 10px;
  }

  td {
    background-color: #fff !important;
  }

  td:first-child {
    position: sticky;
    left: 0;
    padding-left: 6px;
    padding-right: 6px;
    padding-top: 4px;
    padding-bottom: 4px;
    border-bottom: 1px solid #d3d3d3;
    border-color: #d3d3d3;
    z-index: 30;
    background-color: #fafafa !important;
    font-size: 14px !important;
  }

  tbody > tr:first-child {
    font-size: 14px !important;
    font-weight: 600 !important;
  }

  .CTA {
    display: block !important;
  }

  /* li {
    list-style-type: disc !important;
    color: #17166b !important;
    margin-top: -40px !important;
  } */

  li {
    /* color: #333; */
    font-size: 16px;
    line-height: 1.6em;
    margin-block-start: 1em;
    margin-block-end: 1em;
    display: block;
  }

  div > img {
    margin-left: 0;
    margin-right: 0;
  }

  ul > div > div > img {
    margin-left: 0;
    margin-right: 6px;
    margin-top: 6px;
  }

  div > p {
    font-size: 16px;
  }

  figure > img {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    box-shadow: 0 2px 20px #000 !important;
  }

  td {
    border: 1px solid #28293818 !important;
  }

  ul {
    gap: 20px !important;
  }

  a > strong {
    color: blue;
  }

  @media (max-width: 700px) {
    table {
      width: 800px;
      overflow-x: auto !important;
    }

    figure {
      display: inline-block !important;
      overflow: auto !important;
      width: 100% !important;
    }

    .image {
      width: 100% !important;
    }

    div > div > div > div > div > div > div:first-child {
      display: block;

      padding-bottom: 10px;
    }

    div {
      padding: 0;
    }

    /* div > div:first-child {
      width: 100% !important;
    } */

    /* td:nth-child(2){
      width: 200px;
    } */

    button {
      margin-top: 5px !important;
    }
    p {
      font-size: 16px !important;
    }

    div > ul {
      grid-template-columns: repeat(1, 1fr) !important;
    }
    div > div > div {
      grid-template-columns: repeat(2, 1fr) !important;
      /* padding-top: 10px !important;
      padding-bottom: 10px !important; */
    }

    p > img {
      width: 75% !important;
    }
    div > div {
      row-gap: 0px !important;
    }

    nav {
      display: block !important;
      padding: 20px !important;
    }

    nav > div:first-child {
      width: 98% !important;
    }

    nav > a > div > img {
      display: none !important;
    }

    figure img {
      // width: 90% !important;
      max-height: 380px;
    }
  }
}

.ck-content blockquote {
  overflow: hidden;
  padding-right: 1.5em;
  padding-left: 1.5em;
  margin-left: 0;
  margin-right: 0;
  font-style: italic;
  border-left: solid 5px hsl(0, 0%, 80%);
}
/* ckeditor5-block-quote/theme/blockquote.css */
.ck-content[dir='rtl'] blockquote {
  border-left: 0;
  border-right: solid 5px hsl(0, 0%, 80%);
}
/* ckeditor5-basic-styles/theme/code.css */
.ck-content code {
  background-color: hsla(0, 0%, 78%, 0.3);
  padding: 0.15em;
  border-radius: 2px;
}
/* ckeditor5-font/theme/fontsize.css */
.ck-content .text-tiny {
  font-size: 0.7em;
}
/* ckeditor5-font/theme/fontsize.css */
.ck-content .text-small {
  font-size: 0.85em;
}
/* ckeditor5-font/theme/fontsize.css */
.ck-content .text-big {
  font-size: 1.4em;
}
/* ckeditor5-font/theme/fontsize.css */
.ck-content .text-huge {
  font-size: 1.8em;
}
/* ckeditor5-highlight/theme/highlight.css */
.ck-content .marker-yellow {
  background-color: var(--ck-highlight-marker-yellow);
}
/* ckeditor5-highlight/theme/highlight.css */
.ck-content .marker-green {
  background-color: var(--ck-highlight-marker-green);
}
/* ckeditor5-highlight/theme/highlight.css */
.ck-content .marker-pink {
  background-color: var(--ck-highlight-marker-pink);
}
/* ckeditor5-highlight/theme/highlight.css */
.ck-content .marker-blue {
  background-color: var(--ck-highlight-marker-blue);
}
/* ckeditor5-highlight/theme/highlight.css */
.ck-content .pen-red {
  color: var(--ck-highlight-pen-red);
  background-color: transparent;
}
/* ckeditor5-highlight/theme/highlight.css */
.ck-content .pen-green {
  color: var(--ck-highlight-pen-green);
  background-color: transparent;
}
/* ckeditor5-image/theme/image.css */
.ck-content .image {
  display: flex;
  clear: both;
  text-align: center;
  margin: 0.9em auto;
  min-width: 50px;
  margin-left: auto;
  margin-right: auto;
}

.ant {
  height: 500px !important;
}
